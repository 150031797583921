import React from "react"

// import app components
import { Header, Footer, LightBox } from "components"

const Layout = props => {
  return (
    <>
      <Header />

      {props?.children}

      <Footer />

      <LightBox />
    </>
  )
}

export default Layout
