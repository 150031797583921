import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import { BackgroundImage, Edges, Spacer, Textarea } from "components"

import VerticalLine from "./vertical-line.png"

const PostPreview = props => {
  const { date, image, headline, text, url } = props

  const formattedDate = new Date(date)
    .toDateString()
    .split(" ")
    .slice(1)
    .join(" ")

  return (
    <>
      <PostContainer>
        <Edges size="cont">
          <Grid container>
            <Grid md={1}></Grid>
            {image && (
              <>
                <Grid item xs={12} sm={5} md={3}>
                  <Link to={url}>
                    <ImageContainer>
                      <BackgroundImage image={image} />
                    </ImageContainer>
                  </Link>
                </Grid>
                <Grid item xs={0} sm={1} md={1}>
                  <SvgCont>
                    <img src={VerticalLine} />
                  </SvgCont>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={image ? 6 : 12} md={image ? 6 : 11}>
              <PostDetails>
                {date && <Item>{formattedDate}</Item>}
                <Spacer mb={5} />
                {headline && (
                  <Spacer mb={5}>
                    <Link to={url}>
                      <Typography variant="h5" children={headline} />
                    </Link>
                  </Spacer>
                )}
                <Excerpt>
                  <Spacer mb={20}>
                    <Textarea className="excerpt-text" content={text} />
                  </Spacer>
                </Excerpt>
                <Grid container>
                  <StyledLink to={url}>
                    <Spacer mr={10}>
                      <CustomButton>Read More</CustomButton>
                    </Spacer>
                  </StyledLink>
                </Grid>
              </PostDetails>
            </Grid>
          </Grid>
        </Edges>
      </PostContainer>
    </>
  )
}

const PostContainer = styled.div`
  padding-bottom: 50px;
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 175px;

  @media (min-width: 768px) {
    height: 250px;
    max-width: 310px;
  }
`

const Excerpt = styled.div`
  .excerpt-text {
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  text-transform: uppercase;
`

const PostDetails = styled.div`
  height: 100%;
  padding: 20px 0px;
  color: white;

  a {
    color: white;
  }

  @media (min-width: 768px) {
    padding: 20px 0px;
  }
`

const CustomButton = styled.div`
  border: 1px solid white;
  border-radius: 20px;
  padding: 5px 45px;
`

const SvgCont = styled.div`
  display: none;

  @media (min-width: 600px) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`

const Item = styled.div``

export default PostPreview
