import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, BackgroundImage } from "components"

const Gallery = ({ images }) => {
  const firstItems = images.slice(0, 3)
  const restItems = images.slice(3)

  const limitedGallery = firstItems.length < 3

  return (
    <Container>
      <Edges size="lg" noPadding>
        <Layout1 limitedgallery={limitedGallery}>
          {firstItems &&
            firstItems.map(({ image1, image2, image3, layout }, i) => {
              const renderedImages = []

              if (image1) {
                renderedImages.push(
                  <Image key={`image-1`} className={`layout-${layout} image-1`}>
                    <BackgroundImage image={image1} />
                    {image1?.altText && (
                      <Caption className="caption" children={image1.altText} />
                    )}
                  </Image>
                )
              }

              if (layout === "3") {
                renderedImages.push(
                  <Image key={`image-2`} className={`layout-${layout} image-2`}>
                    <BackgroundImage image={image2} />
                    {image2?.altText && (
                      <Caption className="caption" children={image2.altText} />
                    )}
                  </Image>,
                  <Image key={`image-3`} className={`layout-${layout} image-3`}>
                    <BackgroundImage image={image3} />
                    {image3?.altText && (
                      <Caption className="caption" children={image3.altText} />
                    )}
                  </Image>
                )
              }

              return (
                <Images key={i} className={`style-${i} layout-${layout}`}>
                  {renderedImages}
                </Images>
              )
            })}
        </Layout1>

        <Layout2>
          {restItems.map(({ image1, image2, image3, layout }, i) => {
            if (!image1 && !image2 && !image3) {
              return null
            }

            const renderedImages = [
              <Image key={`image-1`} className={`layout-${layout} image-1`}>
                <BackgroundImage image={image1} />
                {image1?.altText && (
                  <Caption className="caption" children={image1.altText} />
                )}
              </Image>
            ]

            if (layout === "3") {
              renderedImages.push(
                <Image key={`image-2`} className={`layout-${layout} image-2`}>
                  <BackgroundImage image={image2} />
                  {image2?.altText && (
                    <Caption className="caption" children={image2.altText} />
                  )}
                </Image>,
                <Image key={`image-3`} className={`layout-${layout} image-3`}>
                  <BackgroundImage image={image3} />
                  {image3?.altText && (
                    <Caption className="caption" children={image3.altText} />
                  )}
                </Image>
              )
            }

            return (
              <Images key={i} className={`style-2 layout-${layout}`}>
                {renderedImages}
              </Images>
            )
          })}
        </Layout2>
      </Edges>
    </Container>
  )
}

const Container = styled(({ padding, ...rest }) => <div {...rest} />)`
  margin-top: 80px;
  background: #252525;

  @media (min-width: 1180px) {
    padding-bottom: ${props => (props.padding ? "220px" : "1px")};
  }
`

const Layout1 = styled(({ limitedgallery, ...rest }) => <div {...rest} />)`
  @media (max-width: 1179px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: 0 auto;

    > div {
      height: 60vw;
      width: 100%;
      margin-bottom: 4px;
      transform: translateY(-80px);
    }
  }

  @media (min-width: 1180px) {
    width: 100%;
    margin-bottom: 80px;
    display: grid;
    gap: 4px 4px;
    justify-content: center;
    grid-template-columns: 200px 200px 200px 200px 200px;
    grid-template-rows: 200px 200px 200px ${props =>
        props.limitedgallery ? "0px" : "200px"};
    grid-template-areas:
      "style-0 style-0 style-0 style-1 style-1"
      "style-0 style-0 style-0 style-1 style-1"
      "style-2 style-2 style-2 style-1 style-1"
      "style-2 style-2 style-2 style-1 style-1";

    .style-0 {
      grid-area: style-0;
      transform: translateY(-80px);
    }
    .style-1 {
      grid-area: style-1;
      transform: translate(80px, 30px);
    }
    .style-2 {
      grid-area: style-2;
      transform: translate(-120px, 80px);
    }
  }
`

const Layout2 = styled.div`
  @media (max-width: 1179px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: 0 auto;

    > div {
      height: 60vw;
      width: 100%;
      margin-bottom: 4px;
      transform: translateY(-80px);
    }
  }

  @media (min-width: 1180px) {
    display: grid;
    gap: 4px 4px;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    padding-top: 120px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .style-2 {
    overflow: hidden;
  }
`

const Images = styled.div`
  display: grid;
  gap: 4px 4px;

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .image-1 {
    grid-area: image-1;
  }
  .image-2 {
    grid-area: image-2;
  }
  .image-3 {
    grid-area: image-3;
  }

  &.layout-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "image-1";
    grid-template-rows: 1fr;

    @media (min-width: 1180px) {
      grid-template-rows: 400px;
    }
  }

  .layout-3 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "image-1 image-1 image-2"
      "image-1 image-1 image-3";

    @media (min-width: 1180px) {
      grid-template-columns: 200px 200px 200px;
      grid-template-rows: 200px 200px;
    }
  }

  &.style-0 {
    &.layout-3 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "image-2 image-1 image-1"
        "image-3 image-1 image-1";

      @media (min-width: 1180px) {
        grid-template-columns: 200px 200px 200px;
        grid-template-rows: 200px 200px;
      }
    }
  }

  &.style-1 {
    &.layout-3 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "image-1 image-1"
        "image-1 image-1"
        "image-2 image-3";

      @media (min-width: 1180px) {
        grid-template-columns: 200px 200px;
        grid-template-rows: 200px 200px 200px;
      }
    }
  }

  &.style-2 {
    &.layout-3 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "image-1 image-1 image-2"
        "image-1 image-1 image-3";

      @media (min-width: 1180px) {
        grid-template-columns: 200px 200px 200px;
        grid-template-rows: 200px 200px;
      }
    }
  }
`

const Image = styled.div`
  position: relative;
`

const Caption = styled(Typography)`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  opacity: 0;
  transition: ease all 0.5s;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #252525;
  text-shadow: 0px 0px 8px #ffffff;
`

export default Gallery
