import React, { Fragment, useState } from "react"
import styled, { css } from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import { BackgroundImage } from "components"
import { useStore } from "store"
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"
import * as theme from "theme"
import ChevronDown from "icons/chevron-down.svg"
import Logo from "icons/walker-bay-logo-hz-black.svg"
import MenuCloseIcon from "icons/menu-close.svg"

const MobileMenu = (props) => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const items = useMenuItems("flyout-menu")

  const [activeItems, setActiveItems] = useState([])
  const [submenu, setSubmenu] = useState({ id: "", items: [] })

  const {
    themeOptions: {
      siteOptions: {
        menu: { image }
      }
    }
  } = useOptionsQuery()

  const handleArrowClick = (id) => {
    let newItems = [...activeItems]

    if (activeItems.includes(id)) {
      newItems = newItems.filter((i) => i !== id)
    } else {
      newItems.push(id)
    }

    setActiveItems(newItems)
  }

  const handleCloseMenu = () => {
    setSubmenu({ id: "", items: [] })
    dispatch({ type: "SET_MENU", payload: false })
  }

  return (
    <MenuContainer {...props} menuState={menu}>
      <MenuHeader to="/" onClick={handleCloseMenu}>
        <Logo />
      </MenuHeader>

      <Menu>
        <MenuIcon>
          <MenuCloseIcon onClick={handleCloseMenu} />
        </MenuIcon>
        <RequestLink
          href={"/request-quotation/"}
          activeStyle={{ color: theme.colors.primary }}
          onClick={handleCloseMenu}
        >
          <FirstLevelTypography
            color="inherit"
            component="div"
            variant="subtitle1"
            children={"Request a Quotation"}
          />
        </RequestLink>
        {items &&
          items.map(({ id, url, label, childItems, cssClasses }) => {
            return (
              <MenuItem key={id}>
                {childItems && childItems.nodes.length ? (
                  <>
                    {url === "#" ? (
                      <MenuLinkContainer onClick={() => handleArrowClick(id)}>
                        <FirstLevelTypography
                          color="inherit"
                          component="div"
                          variant="subtitle1"
                          children={Parser(label)}
                        />
                        <ChevronContainer>
                          <ChevronDown />
                        </ChevronContainer>
                      </MenuLinkContainer>
                    ) : (
                      <MenuLinkContainer>
                        <MenuLink
                          to={getUrlPath(url)}
                          activeStyle={{ color: theme.colors.primary }}
                          onClick={handleCloseMenu}
                        >
                          <FirstLevelTypography
                            color="inherit"
                            component="div"
                            variant="subtitle1"
                            children={Parser(label)}
                          />
                        </MenuLink>
                        <ChevronContainer onClick={() => handleArrowClick(id)}>
                          <ChevronDown />
                        </ChevronContainer>
                      </MenuLinkContainer>
                    )}

                    <SubMenu
                      className={`sub-menu ${cssClasses.join(" ")}`}
                      active={activeItems.includes(id)}
                    >
                      {childItems.nodes.map((o, i) => {
                        return (
                          <Fragment key={i}>
                            {o.url === "#" ? (
                              <SubMenuLinkContainer
                                onClick={() =>
                                  setSubmenu({
                                    id: o.id,
                                    items: o.childItems.nodes
                                  })
                                }
                                className={submenu.id === o.id && "active"}
                                visible={!!activeItems.length}
                              >
                                <SecondLevelTypography
                                  component="div"
                                  variant="body2"
                                  children={Parser(o.label)}
                                  color="inherit"
                                />
                              </SubMenuLinkContainer>
                            ) : (
                              <SubMenuLink
                                to={getUrlPath(o.url)}
                                activeClassName="active"
                                onClick={handleCloseMenu}
                                visible={!!activeItems.length}
                              >
                                <SecondLevelTypography
                                  component="div"
                                  variant="subtitle1"
                                  children={Parser(o.label)}
                                  onClick={handleCloseMenu}
                                  color="inherit"
                                />
                              </SubMenuLink>
                            )}
                          </Fragment>
                        )
                      })}
                    </SubMenu>
                  </>
                ) : (
                  <>
                    {url.includes("http") ? (
                      <ExternalLink
                        href={url}
                        target="_blank"
                        className={cssClasses.join(" ")}
                      >
                        <FirstLevelTypography
                          color="inherit"
                          component="div"
                          variant="subtitle1"
                          children={Parser(label)}
                        />
                      </ExternalLink>
                    ) : (
                      <MenuLink
                        to={getUrlPath(url)}
                        onClick={handleCloseMenu}
                        activeStyle={{ color: theme.colors.primary }}
                        className={cssClasses.join(" ")}
                      >
                        <FirstLevelTypography
                          color="inherit"
                          component="div"
                          variant="subtitle1"
                          children={Parser(label)}
                        />
                      </MenuLink>
                    )}
                  </>
                )}
              </MenuItem>
            )
          })}
      </Menu>

      <BoatMenuContainer active={!!submenu.id}>
        <BoatMenu active={!!submenu.id}>
          {submenu.items.length > 0 &&
            submenu.items.map((o, i) => {
              return (
                <Fragment key={o.id}>
                  {i !== 0 && <Divider />}
                  <FlexEnd>
                    <BoatCategory children={o.label} />
                    <span>&nbsp; | &nbsp;</span>
                    <BoatCompare onClick={handleCloseMenu}>
                      <Link to={"/model-comparison"}>Compare Models</Link>
                    </BoatCompare>
                  </FlexEnd>
                  {o.childItems.nodes.length > 0 && (
                    <Boats>
                      {o.childItems.nodes.map((p) => {
                        return (
                          <Boat key={p.id} to={p.url} onClick={handleCloseMenu}>
                            <BoatImage>
                              {p?.acf?.image && (
                                <BackgroundImage image={p.acf.image} />
                              )}
                            </BoatImage>
                            <BoatTitle children={p.label} />
                          </Boat>
                        )
                      })}
                    </Boats>
                  )}
                </Fragment>
              )
            })}
        </BoatMenu>
      </BoatMenuContainer>

      <BackgroundImage image={image} />
    </MenuContainer>
  )
}

export default MobileMenu

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RequestLink = styled.a`
  font-family: "Noto Sans JP";

  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-letter-spacing: -0.015em;
  -moz-letter-spacing: -0.015em;
  -ms-letter-spacing: -0.015em;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 15px;
  border-radius: 5px;
`

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const MenuContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  ${(props) => (!!props.menuState ? menuOpen : menuClose)}
`

const Menu = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  max-width: 375px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px;
  overflow-y: auto;
  overflow-x: hidden;
  mix-blend-mode: normal;
  backdrop-filter: blur(50px);
  background: rgba(0, 0, 0, 0.9);

  /* if backdrop support: very transparent and blurred */
  @supports (
    (-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))
  ) {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(50px);
  }

  @media (min-width: 768px) {
    padding: 0 40px;
  }
`

const MenuHeader = styled(Link)`
  @media (min-width: 768px) {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: calc(100% - 375px);
    height: 70px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    backdrop-filter: blur(50px);
  }

  svg {
    height: 25px;

    path {
      fill: #fff;
    }
  }
`

const MenuIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
  margin-bottom: 20px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(10px);

  svg {
    path {
      fill: #fff;
    }
  }
`

const ItemCSS = `
  position: relative;
  color: ${theme.colors.primary};
  text-decoration: none;
  width: 100%;
  cursor: pointer;
`

const MenuLinkContainer = styled.div`
  ${ItemCSS}
  display: flex;
  justify-content: space-between;
`

const MenuItem = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  ${ItemCSS}
`

const MenuLink = styled(Link)`
  ${ItemCSS}

  && {
    &.menu-item-xs {
      .MuiTypography-subtitle1 {
        font-size: 14px !important;
      }
    }
  }
`

const ExternalLink = styled.a`
  ${ItemCSS}

  && {
    &.menu-item-xs {
      .MuiTypography-subtitle1 {
        font-size: 14px !important;
      }
    }
  }
`

const SubMenu = styled.div`
  transition: ease all 0.1s;

  ${(props) =>
    props.active
      ? css`
          height: auto;
          margin-top: 20px;
        `
      : css`
          height: 0;
          margin-top: 0;
        `}
`

const SubMenuLinkContainer = styled.div`
  position: relative;
  padding: 8px 0 8px 20px;
  color: #ffffff;
  transform: translateX(100%);
  opacity: 0;
  transition-delay: 0.1s;
  transition: ease all 0s;

  ${(props) =>
    props.visible &&
    css`
      transition: ease all 0.4s;
      transform: translateX(0);
      opacity: 0.7;

      &:hover,
      &.active {
        opacity: 1 !important;
      }
    `}

  @media (min-width: 768px) {
    &.active {
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: -28px;
        top: 50%;
        width: 28px;
        height: 1px;
        background: #ffffff;
      }
    }
  }
`

const SubMenuLink = styled(({ visible, ...rest }) => <Link {...rest} />)`
  display: block;
  padding: 8px 0 8px 20px;
  color: #ffffff;
  transform: translateX(100%);
  opacity: 0;
  transition-delay: 0.1s;
  transition: ease all 0s;

  ${(props) =>
    props.visible &&
    css`
      transition: ease all 0.4s;
      transform: translateX(0);
      opacity: 0.7;

      &:hover,
      &.active {
        opacity: 1 !important;
      }
    `}
`

const FirstLevelTypography = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;

    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #ffffff;

    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`

const SecondLevelTypography = styled(Typography)`
  && {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
  }
`

const BoatMenuContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 70px;
  width: 100%;
  height: calc(100% - 70px);
  overflow: hidden;
  pointer-events: ${({ active }) => (active ? "all" : "none")};

  @media (min-width: 768px) {
    width: calc(100% - 375px);
  }
`

const BoatMenu = styled.div`
  opacity: 1;
  transform: ${({ active }) => (active ? "translateX(0)" : "translateX(100%)")};
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  padding: 20px 25px 20px 10px;
  background: #fff;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 25px 60px;
  }
`

const BoatCategory = styled(Typography)`
  && {
    text-align: right;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`

const BoatCompare = styled(Typography)`
  text-align: right;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  margin-bottom: 10px;

  a {
    color: ${theme.colors.secondary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Boats = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const Boat = styled(Link)`
  width: 150px;
  margin: 0px 0px 15px 10px;

  @media (min-width: 768px) {
    margin: 0px 0px 0px 15px;
  }
`

const BoatImage = styled.div`
  position: relative;
  height: 65px;
  margin-bottom: 5px;
`

const BoatTitle = styled(Typography)`
  && {
    width: 100%;
    text-align: right;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #000000;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background: linear-gradient(
    90deg,
    rgba(216, 216, 216, 0) 0%,
    rgba(216, 216, 216, 1) 100%
  );
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      {
        wp {
          themeOptions {
            siteOptions {
              menu {
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wp
}
