import React from "react"

import ChevronDown from "../icons/chevron-down.svg"
import CloseButton from "../icons/meganav-close-icon.svg"

const getIcon = name => {
  switch (name) {
    case "ChevronDown":
      return <ChevronDown />

    case "CloseButton":
      return <CloseButton />

    default:
  }
}

export default getIcon
