import React, { useState } from "react"
import styled from "styled-components"
import { Typography, Drawer } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import {
  Button,
  BackgroundImage,
  BackgroundVideo,
  Edges,
  Spacer,
  Reviews,
  VideoImage
} from "components"
import WaveIcon from "icons/wave-2.svg"
import { colors } from "theme"
import { getIcon } from "utils"

const Teaser = props => {
  const {
    teaser1,
    teaser2,
    teaser3,
    separator,
    quote,
    videoTeaser,
    videoTeaserPoster
  } = props

  const [drawerState, setDrawerState] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  return (
    <Container>
      <Edges size="cont" noPadding>
        <Section1>
          <Teaser1>
            {teaser1?.image && (
              <Teaser1Image>
                <BackgroundImage image={teaser1.image} />
              </Teaser1Image>
            )}
            <Teaser1Content>
              {teaser1?.headline && (
                <Typography variant="h2" children={Parser(teaser1.headline)} />
              )}
              {teaser1?.text && (
                <Spacer mt={{ xs: 10 }}>
                  <Typography variant="body1" children={Parser(teaser1.text)} />
                </Spacer>
              )}
              {teaser1?.button && (
                <Spacer mt={{ xs: 30 }}>
                  <Button
                    to={teaser1.button.url}
                    children={teaser1.button.title}
                    variant="outlined"
                    color="secondary"
                  />
                </Spacer>
              )}
            </Teaser1Content>
          </Teaser1>
          <Teaser2>
            <Teaser2Content>
              {teaser2?.headline && (
                <Typography variant="h2" children={Parser(teaser2.headline)} />
              )}
              {teaser2?.text && (
                <Spacer mt={{ xs: 10 }}>
                  <Typography variant="body1" children={Parser(teaser2.text)} />
                </Spacer>
              )}
              {teaser2?.button && (
                <Spacer mt={{ xs: 30 }}>
                  <Button
                    to={teaser2.button.url}
                    children={teaser2.button.title}
                    variant="outlined"
                    color="primary"
                  />
                </Spacer>
              )}
            </Teaser2Content>
          </Teaser2>
        </Section1>
      </Edges>
      <Spacer mb={{ xs: 20, xl: 80 }}>
        <Section2>
          <StylisticSiding>
            {!!separator && (
              <SeparatorContainer>
                <Separator>
                  {separator && <BackgroundImage image={separator} />}
                </Separator>
              </SeparatorContainer>
            )}
            {!!videoTeaser && (
              <VideoTeaserContainer>
                <BackgroundVideo
                  src={videoTeaser.localFile.publicURL}
                  placeholder={videoTeaserPoster}
                />
              </VideoTeaserContainer>
            )}
          </StylisticSiding>
          <Teaser2 width="full">
            <Quote>
              {quote?.text && (
                <Typography variant="h2" children={quote.text} gutterBottom />
              )}
              {quote?.author && (
                <Typography variant="subtitle2" children={quote.author} />
              )}
              {quote?.company && (
                <Typography variant="subtitle2" children={quote.company} />
              )}
            </Quote>
          </Teaser2>
        </Section2>
      </Spacer>

      <Edges size="cont" noPadding>
        <Section2>
          <Teaser3>
            <Teaser3Content>
              <WaveContainer>
                <WaveIcon />
              </WaveContainer>

              {teaser3?.headline && (
                <Spacer mb={{ xs: 10 }}>
                  <Typography
                    variant="h2"
                    children={Parser(teaser3.headline)}
                    color="inherit"
                  />
                </Spacer>
              )}
              {teaser3?.text && (
                <Typography
                  variant="body1"
                  children={Parser(teaser3.text)}
                  color="inherit"
                />
              )}
              {teaser3?.button && (
                <>
                  <Spacer mt={{ xs: 30 }}>
                    <Button
                      children={teaser3.button.title}
                      color="secondary"
                      onClick={toggleDrawer(true)}
                      onKeyDown={toggleDrawer(false)}
                      role="presentation"
                      variant="outlined"
                    />
                  </Spacer>
                  <StyledDrawer
                    anchor="bottom"
                    open={drawerState}
                    onClose={toggleDrawer(false)}
                  >
                    <ChevronClose onClick={toggleDrawer(false)}>
                      {getIcon("ChevronDown")}
                    </ChevronClose>
                    <Reviews reviews={teaser3.reviews} />
                    <Spacer mb={30} />
                    <FlexCenter>
                      <Button
                        to={"/news"}
                        children={"See all Walker Bay reviews"}
                        variant="outlinedPrimary"
                      />
                    </FlexCenter>
                  </StyledDrawer>
                </>
              )}
            </Teaser3Content>

            {teaser3?.image && (
              <Teaser3Image>
                <VideoImage
                  imageAlignment="none"
                  image={teaser3.image}
                  responsive={true}
                  youtubeUrl={teaser3.video}
                />
              </Teaser3Image>
            )}
          </Teaser3>
        </Section2>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: radial-gradient(
    128.75% 67.2% at 164.69% 61.38%,
    #000000 37.82%,
    #252525 100%
  );
  background-size: cover;

  @media (min-width: 768px) {
    background-size: 50% 100%;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: #fff;
    }
  }
`

const Section1 = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Teaser1 = styled.div`
  width: 100%;
  color: ${colors.text.light};

  @media (min-width: 768px) {
    width: 50%;
    margin-bottom: 0px;
  }

  @media (min-width: 1320px) {
    margin-bottom: 40px;
  }
`

const Teaser1Image = styled.div`
  position: relative;
  width: 100%;
  height: 280px;
`

const Teaser1Content = styled.div`
  padding: 30px 20px 40px;

  @media (min-width: 768px) {
    padding-left: 20px;
  }

  @media (min-width: 1320px) {
    padding-left: 100px;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
`

const SeparatorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
`

const VideoTeaserContainer = styled.div`
  max-height: 100px;
  overflow: hidden;
`

const Separator = styled.div`
  height: 100%;

  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    width: 50vw;
  }
`

const StylisticSiding = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`

const Teaser2 = styled(({ width, ...rest }) => <div {...rest} />)`
  background: #fff;
  color: ${colors.text.dark};
  position: relative;
  padding: 30px 20px 40px;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 40px 20px 40px 30px;
    max-width: ${props => (props.width === "full" ? "660px" : "100%")};
    width: 50%;
  }

  @media (min-width: 1320px) {
    padding: 60px 20px 60px 100px;
  }
`

const Teaser2Content = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 180px;
  }
`

const Quote = styled.div``

const Section2 = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
`

const Teaser3 = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const WaveContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  @media (min-width: 768px) {
    width: 50vw;
  }

  svg {
    max-width: 100%;
  }
`

const Teaser3Content = styled.div`
  position: relative;
  padding: 30px 20px;
  background: #252525;
  color: ${colors.text.light};
  order: 2;

  @media (min-width: 768px) {
    order: 1;
    width: 50%;
    padding: 60px 100px;
  }

  @media (min-width: 1030px) {
    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: calc(50vw - 515px);
      background: #252525;
    }
  }
`

const Teaser3Image = styled.div`
  position: relative;
  width: 100%;
  height: 238px;
  order: 1;

  @media (min-width: 768px) {
    width: 50%;
    height: 100%;
    order: 2;
  }
`

const ChevronClose = styled.button`
  background: transparent;
  border: 0px;
  color: ${colors.tertiary};

  &:active,
  &:focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.tertiary};
    outline: none;
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    background: #252525;
    padding: 15px 0px 30px;
  }
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

export default Teaser
