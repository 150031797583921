import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "theme"
// import app components
import { Edges, Spacer, BackgroundImage } from "components"
import * as theme from "theme"

import { Typography, Drawer } from "@material-ui/core"
import { Button, Reviews } from "components"
import { getIcon } from "utils"

const HomeTextImage2 = (props) => {
  const {
    section,
    reviews,

    image,
    ...rest
  } = props

  const [drawerState, setDrawerState] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  return (
    <Container {...rest}>
      <Spacer>
        <Edges size="ml">
          <Grid>
            <ImageContainer>
              <BackgroundImage image={image}></BackgroundImage>
            </ImageContainer>
            <ContentContainer>
              {section?.map(({ title, description, button }) => {
                return (
                  <Section>
                    {title && <Typography variant="h2" children={title} />}
                    {description && (
                      <Typography variant="body1" children={description} />
                    )}
                    {button && (
                      <div>
                        <StyledButton>
                          <Button
                            to="#"
                            children={button.title}
                            variant="outlined"
                            color="primary"
                            onClick={toggleDrawer(true)}
                            onKeyDown={toggleDrawer(false)}
                          />
                        </StyledButton>
                        <StyledDrawer
                          anchor="bottom"
                          open={drawerState}
                          onClose={toggleDrawer(false)}
                        >
                          <ChevronClose onClick={toggleDrawer(false)}>
                            {getIcon("ChevronDown")}
                          </ChevronClose>
                          <Reviews reviews={reviews} />
                          <Spacer mb={30} />
                          <FlexCenter>
                            <Button
                              to={"/news"}
                              children={"See all Walker Bay reviews"}
                              variant="outlinedPrimary"
                            />
                          </FlexCenter>
                        </StyledDrawer>
                      </div>
                    )}
                  </Section>
                )
              })}
            </ContentContainer>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const ChevronClose = styled.button`
  background: transparent;
  border: 0px;
  color: ${colors.tertiary};

  &:active,
  &:focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.tertiary};
    outline: none;
  }
`
const StyledButton = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 7px;
  }
`
const Container = styled.div`
  position: relative;

  background: ${({ background }) =>
    background === "gradient"
      ? "radial-gradient(77.11% 77.11% at 7.58% -22.46%, #252525 0%, #000000 100%)"
      : "white"};
`

const Grid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  order: 1;

  margin-bottom: 30px;
  cursor: ${({ hasVideo }) => (hasVideo ? "pointer" : "default")};

  @media (min-width: 960px) {
    width: 40%;
    height: unset;
    order: ${({ alignment }) => (alignment !== "left" ? 1 : 3)};
    margin-bottom: 0;

    > div {
      max-width: 800px;

      ${({ alignment }) =>
        alignment !== "left"
          ? "left: unset !important; transform: translateX(-4px);"
          : "right: unset !important; transform: translateX(4px);"}

      ${({ hasVideo }) =>
        hasVideo
          ? "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 28px)"
          : "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 8px)"};
    }
  }
`

const ContentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  color: ${theme.colors.text.dark};
  order: 2;

  @media (min-width: 960px) {
    width: 60%;
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 140px;

    ${(props) =>
      props.alignment !== "left"
        ? "padding-left: 100px"
        : "padding-right: 70px"};
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    background: #252525;
    padding: 15px 0px 30px;
  }
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

export default HomeTextImage2
