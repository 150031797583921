import React from "react"
import styled from "styled-components"
import {
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// import app components
import { Spacer } from "components"
import * as theme from "theme"

const Accordion = props => {
  const { headline, items } = props

  return (
    <Container>
      {headline && (
        <Spacer mb={20}>
          <Typography variant="h2" children={headline} />
        </Spacer>
      )}
      {items &&
        items.map((o, i) => {
          return (
            <StyledAccordion key={i}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${i + 1}-content`}
                id={`panel-${i + 1}-header`}
              >
                {o.title && (
                  <Typography
                    variant="button"
                    children={o.title}
                    color="inherit"
                  />
                )}
              </StyledAccordionSummary>
              <StyledAccordionDetails>{o.content}</StyledAccordionDetails>
            </StyledAccordion>
          )
        })}
    </Container>
  )
}

const Container = styled.div``

const StyledAccordion = styled(MuiAccordion)`
  &:before {
    background-color: ${theme.colors.primary};
  }

  &.Mui-expanded {
    margin: 0;

    &:before {
      opacity: 1;
    }
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    min-height: auto;
    padding: 0;
    color: ${theme.colors.primary};
    border-bottom: 1px solid ${theme.colors.primary};

    &.Mui-expanded {
      color: ${theme.colors.secondary};
    }

    .MuiAccordionSummary-content {
      margin: 12px 0;
    }

    svg {
      path {
        fill: ${theme.colors.primary};
      }
    }
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 16px 0;
    display: block;
  }
`

export default Accordion
