import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import { colors } from "theme"

// import app components
import { Edges, BackgroundImage, Spacer, Button } from "components"

const Featured = (props) => {
  const { imageTop, headline, text, button, imageRight } = props

  return (
    <Container>
      <Edges size="lg">
        <Images>
          <ImageTopContainer>
            {imageTop && <BackgroundImage image={imageTop} />}
          </ImageTopContainer>

          <ImageRightContainer>
            {imageRight && <BackgroundImage image={imageRight} />}
          </ImageRightContainer>
        </Images>

        <Edges size="md">
          <Content>
            {headline && (
              <Typography
                variant="h2"
                children={Parser(headline)}
                color="inherit"
              />
            )}
            {text && (
              <Spacer mt={{ xs: 10 }}>
                <Typography variant="body1" children={Parser(text)} />
              </Spacer>
            )}
            {button && (
              <Spacer mt={{ xs: 30 }}>
                <Button
                  to={button.url}
                  children={button.title}
                  variant="outlined"
                  color="secondary"
                />
              </Spacer>
            )}
          </Content>
        </Edges>
      </Edges>
    </Container>
  )
}

const Container = styled.div``

const Images = styled.div`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    height: 400px;
  }
`

const ImageTopContainer = styled.div`
  position: relative;
  width: 100%;
  height: 240px;

  @media (min-width: 768px) {
    width: 60%;
    height: 400px;
  }
`

const ImageRightContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: relative;
    width: 30%;
    height: 600px;
    transform: translate(20px, 120px);
  }
`

const Content = styled.div`
  max-width: 500px;
  padding-bottom: 70px;
  color: ${colors.text.light};
`

export default Featured
