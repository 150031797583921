import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, BackgroundImage, Textarea, Spacer } from "components"
import * as theme from "theme"

import WaveAltIcon from "icons/flipped-wave-sm.svg"

const PageIntro = props => {
  const {
    headline,
    image,
    text
  } = props

  const {
    themeOptions: {
      siteOptions: {
        news
      }
    }
  } = useOptionsQuery()

  const heroImage = image ? image : news.postBackgroundImage

  return (
    <>
      <Container>
        <Overlay />
        <Hero>
          <Edges size="cont">
            <Grid container>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={10}>
                {headline && (
                  <Typography variant="h1" children={headline} />
                )}
              </Grid>
            </Grid>
          </Edges>
        </Hero>
        {heroImage && (
          <HeroImageContainer>
            <BackgroundImage image={heroImage} />
          </HeroImageContainer>
        )}
      </Container>
      <HeroIntroText text={text}>
        <Edges size="cont">
          <WaveContainer>
            <WaveAltIcon />
          </WaveContainer>
          {text && (
            <Grid container>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={10}>
                <Spacer pb={30} pt={30}>
                  <Textarea content={text} />
                </Spacer>
              </Grid>
            </Grid>
          )}
        </Edges>
      </HeroIntroText>
    </>
  )
}

const Container = styled.div`
  position: relative;
  height: 140px;
  padding: ${({ border }) => (border ? "20px" : 0)};

  @media (min-width: 600px) {
    height: 200px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  }
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0px;
  left: 0px;
  z-index: 1;

  @media (min-width: 768px) {
    height: 170px;
  }
`

const Hero = styled.div`
  align-items: center;
  background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  backdrop-filter: blur(12px);
  bottom: 0;
  color: white;
  display: flex;
  height: 110px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  h1 {
    margin-bottom: 12px;
  }

  @media (min-width: 600px) {
    height: 170px;
  }
`

const HeroImageContainer = styled.div`
  position: relative;
  height: 140px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 200px;
  }
`

const HeroIntroText = styled(({ text, ...rest }) => (
  <div {...rest} />)
)`
  background: radial-gradient(17.98% 717.28% at 14.84% -12.35%, #000000 41.89%, #252525 100%);
  color: white;
  padding: ${props => props.text ? "20px 0px" : "0px"};
  position: relative;
  width: 100%;
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: -5px;

  svg {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    top: 0px;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`{
  wp {
    themeOptions {
      siteOptions {
        news {
          headerTitle
          postBackgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}
`
  )
  return wp
}

export default PageIntro
