import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import {
  BackgroundImage,
  Button,
  Edges,
  Spacer,
  Textarea
} from "components"

const NewsTeaser = props => {
  const {
    newsPosts
  } = props

  const formatDate = (date) => {
    return new Date(date)
      .toDateString()
      .split(" ")
      .slice(1)
      .join(" ")
  }

  const truncate = (theText, length) => {
    if (!theText) {
      return ""
    }

    if (!length) {
      length = 150;
    }

    return theText.length > length ? theText.substring(0, (length - 20)) + "..." : theText
  }

  return (
    <>
    <NewsTeaserContainer>
      <Edges size="cont">
        <Grid container>
          <Grid item md={6}>
            <Typography
              variant="h2"
              children="Product News & Announcements"
            />
          </Grid>
        </Grid>
        <Spacer mb={30} />
        <Grid container spacing={2}>
          {newsPosts && newsPosts.map((o, i) => {
            return (
              <Grid key={i} item sm={6} md={4}>
                {!!o.acf.image && (
                  <Link to={o.uri}>
                    <ImageContainer>
                      <BackgroundImage image={o.acf.image} />
                    </ImageContainer>
                  </Link>
                )}

                <PostDetails>
                  <Spacer mb={5} />
                  {o.title && (
                    <Spacer mb={5}>
                      <Link to={o.uri}>
                        <Typography children={o.title} />
                      </Link>
                    </Spacer>
                  )}

                  {formatDate(o.date)}

                  <Spacer mt={20} mb={20}>
                    <Textarea
                      content={truncate(o.acf.text, 175)}
                      stripTags={true}
                    />
                  </Spacer>

                  <Spacer mt={20}>
                    <Button
                      children="Read more"
                      to={o.uri}
                      variant="outlined"
                      color="primary"
                    />
                  </Spacer>
                </PostDetails>
              </Grid>
            )
          })}

        </Grid>
      </Edges>
    </NewsTeaserContainer>
    </>
  )
}

const NewsTeaserContainer = styled.div`
  padding: 60px 0px;
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 175px;

  @media (min-width: 768px) {
    height: 250px;
  }
`

const PostDetails = styled.div`
  height: 100%;
  padding: 15px;

  a {
    color: black;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }
`

export default NewsTeaser
