import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],
  typography: {
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    h1: {
      fontFamily: "Noto Sans JP",
      fontWeight: 300,
      fontSize: "32px",
      [breakpoints.up("sm")]: {
        fontSize: "40px"
      },
      [breakpoints.up("md")]: {
        fontSize: "50px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "60px"
      }
    },
    h2: {
      fontFamily: "Noto Sans JP",
      fontSize: "30px",
      lineHeight: "45px",
      fontWeight: 300
    },
    h3: {
      fontFamily: "Roboto Condensed",
      fontSize: "22px",
      lineHeight: "26px",
      fontWeight: 700,
      marginTop: "20px",
      marginBottom: "10px"
    },
    h4: {
      fontFamily: "Noto Sans JP",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 700,
      marginTop: "15px"
    },
    h5: {},
    h6: {},
    subtitle1: {
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 300,
      color: colors.secondary
    },

    subtitle2: {
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      textTransform: "uppercase",
      color: colors.secondary
    },
    body1: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 400
    },
    body2: {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400
    },
    caption: {
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "20px"
    },
    button: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1rem",
      fontWeight: 400
    }
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "10px"
      }
    },
    MuiButton: {
      root: {
        borderRadius: "25px",
        letterSpacing: "0.1em"
      },
      outlined: {
        padding: "5px 40px"
      },
      outlinedPrimary: {
        color: colors.secondary,
        minWidth: "200px"
      },
      outlinedSecondary: {
        color: "#E9E1BB",
        border: "1px solid#fff",
        minWidth: "200px"
      }
    },
    MuiInput: {
      root: {
        "&:after": {
          content: "none !important"
        }
      }
    }
  }
})
