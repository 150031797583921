import React from "react"
import styled, { css } from "styled-components"

import BackgroundImage from "../backgroundImage"

export default function BackgroundVideo(props) {
  const {
    src,
    autoPlay,
    loop,
    muted,
    children,
    overlay,
    contentStyle,
    center,
    placeholder,
    controls,
    ...rest
  } = props

  return (
    <Container {...rest}>
      {!!src && (
        <Video
          id="background-video"
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
          controls={controls}
        >
          <source src={src} type="video/mp4" />
          <source src={src} type="video/ogg" />
          Your browser does not support the video tag.
        </Video>
      )}
      {!!placeholder && (
        <PlaceholderImage
          position="absolute"
          image={placeholder ? placeholder : null}
        />
      )}
      {children && (
        <Content style={contentStyle} center={center} overlay={overlay}>
          {children}
        </Content>
      )}
    </Container>
  )
}

BackgroundVideo.defaultProps = {
  loop: true,
  autoPlay: true,
  muted: true,
  overlay: 0.2,
  center: true,
  height: "100%"
}

const fullSize = css`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  position: relative;
  width: 100%;
`
const Video = styled.video`
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;

  /* @media (max-width: 600px) {
    display: none;
  } */
`

const Content = styled.div`
  ${props =>
    !!props.overlay &&
    css`
      &::after {
        content: "";
        position: absolute;
        ${fullSize}
        background: rgba(0,0,0, ${props.overlay});
        z-index: -1;
      }
    `}
  ${props =>
    props.center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    `}
  position: absolute;
  ${fullSize}
  height: 100%;
  z-index: 1;
  padding: 30px;
  color: white;
`

const PlaceholderImage = styled(BackgroundImage)`
  z-index: 0;
`
