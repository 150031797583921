import React from "react"
import styled from "styled-components"

// import app components
import { Button } from "components"

const Pagination = props => {
  const { onNext, onPrev, prevHidden, nextDisabled, nextText } = props

  return (
    <Container>
      <div>
        {!prevHidden && (
          <Button children="Prev" variant="contained" onClick={onPrev} />
        )}
      </div>
      <div>
        <Button
          children={nextText}
          onClick={!nextDisabled ? onNext : null}
          disabled={nextDisabled}
          variant="contained"
          color="secondary"
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: 440px) {
    justify-content: flex-start;
  }

  button {
    margin: 0 10px;
    padding: 10px 30px;

    @media (min-width: 440px) {
      margin: 0 20px 0 0;
      padding: 10px 60px;
    }
  }
`

export default Pagination
