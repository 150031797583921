import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"

import { Typography } from "@material-ui/core"

// import app components
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"
import * as theme from "theme"

const FooterMenu = props => {
  const menu1 = useMenuItems("footer-menu-1")
  const menu2 = useMenuItems("footer-menu-2")

  return (
    <Menu {...props}>
      <Column>
        {menu1 &&
          menu1.map(({ id, url, label }) => (
            <MenuItem key={id}>
              {url.includes("http") ? (
                <ExternalLink href={url} target="_blank">
                  <StyledTypography
                    component="div"
                    variant="body2"
                    children={Parser(label)}
                    color="inherit"
                  />
                </ExternalLink>
              ) : (
                <MenuLink to={getUrlPath(url)}>
                  <StyledTypography
                    component="div"
                    variant="body2"
                    children={Parser(label)}
                    color="inherit"
                  />
                </MenuLink>
              )}
            </MenuItem>
          ))}
      </Column>
      <Column>
        {menu2 &&
          menu2.map(({ id, url, label }) => (
            <MenuItem key={id}>
              {url.includes("http") ? (
                <ExternalLink href={url} target="_blank">
                  <StyledTypography
                    component="div"
                    variant="body2"
                    children={Parser(label)}
                    color="inherit"
                  />
                </ExternalLink>
              ) : (
                <MenuLink to={getUrlPath(url)}>
                  <StyledTypography
                    component="div"
                    variant="body2"
                    children={Parser(label)}
                    color="inherit"
                  />
                </MenuLink>
              )}
            </MenuItem>
          ))}
      </Column>
    </Menu>
  )
}

export default FooterMenu

const Menu = styled.div`
  display: flex;
  margin-bottom: 40px;

  a {
    text-decoration: none;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 70px;
`

const MenuItem = styled.div`
  display: flex;
  position: relative;
`

const MenuLink = styled(Link)`
  padding: 4px 0;
  margin-bottom: 5px;
  color: #ffffff;

  &:hover {
    color: ${theme.colors.secondary};
  }
`

const ExternalLink = styled.a`
  padding: 4px 0;
  margin-bottom: 5px;
  color: #ffffff;

  &:hover {
    color: ${theme.colors.secondary};
  }
`

const StyledTypography = styled(Typography)`
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`
