import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`

  a {
    text-decoration: none;
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  body {
    padding-top: 70px;
    background: #fff;
    ${
      "" /* .react-multi-carousel-list .container > .react-multi-carousel-dot-list .heroCarouselDots {
      justify-content: flex-end !important;

    } */
    }
  }
   .heroCarouselDots {
      justify-content: flex-end !important;
      z-index:10 !important;
      margin-right:50px !important;
      margin-bottom:50px !important;

    }
`
