import React from "react"
import styled from "styled-components"

const Edges = props => {
  return (
    <Container className="edges" {...props}>
      {props.children}
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: ${props => (props.noPadding ? 0 : "0 20px")};
    
    ${props => props.size === "sm" && "max-width: 600px;"}
    ${props => props.size === "md" && "max-width: 900px;"}
    ${props => props.size === "ml" && "max-width: 1030px;"}
    ${props => props.size === "cont" && "max-width: 1280px;"}
    ${props => props.size === "lg" && "max-width: 1440px;"}

    > .edges {
      padding: 0;
    }
`

export default Edges
