exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-templates-boat-default-js": () => import("./../../../src/templates/boat/default.js" /* webpackChunkName: "component---src-templates-boat-default-js" */),
  "component---src-templates-page-boatlanding-js": () => import("./../../../src/templates/page/boatlanding.js" /* webpackChunkName: "component---src-templates-page-boatlanding-js" */),
  "component---src-templates-page-dealer-js": () => import("./../../../src/templates/page/dealer.js" /* webpackChunkName: "component---src-templates-page-dealer-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-design-js": () => import("./../../../src/templates/page/design.js" /* webpackChunkName: "component---src-templates-page-design-js" */),
  "component---src-templates-page-faq-js": () => import("./../../../src/templates/page/faq.js" /* webpackChunkName: "component---src-templates-page-faq-js" */),
  "component---src-templates-page-forms-js": () => import("./../../../src/templates/page/forms.js" /* webpackChunkName: "component---src-templates-page-forms-js" */),
  "component---src-templates-page-fullpageform-js": () => import("./../../../src/templates/page/fullpageform.js" /* webpackChunkName: "component---src-templates-page-fullpageform-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/page/home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-modelcomparison-js": () => import("./../../../src/templates/page/modelcomparison.js" /* webpackChunkName: "component---src-templates-page-modelcomparison-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/page/news.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-story-js": () => import("./../../../src/templates/page/story.js" /* webpackChunkName: "component---src-templates-page-story-js" */),
  "component---src-templates-page-tenderselector-js": () => import("./../../../src/templates/page/tenderselector.js" /* webpackChunkName: "component---src-templates-page-tenderselector-js" */),
  "component---src-templates-post-default-js": () => import("./../../../src/templates/post/default.js" /* webpackChunkName: "component---src-templates-post-default-js" */),
  "component---src-templates-review-default-js": () => import("./../../../src/templates/review/default.js" /* webpackChunkName: "component---src-templates-review-default-js" */)
}

