import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer, BackgroundImage } from "components"
import { useStore } from "store"

const Boxes = props => {
  const { headline, text, boxes, ...rest } = props

  const [, dispatch] = useStore()

  const showLightBox = index => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: boxes,
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Edges size="ml">
          {(headline || text) && (
            <Spacer mb={{ xs: 40 }}>
              <Introduction>
                {headline && <Typography variant="h2" children={headline} />}
                {text && (
                  <Spacer mt={20}>
                    <Typography children={text} />
                  </Spacer>
                )}
              </Introduction>
            </Spacer>
          )}

          {boxes && (
            <Grid container justify="space-between">
              {boxes.map((o, i) => {
                return (
                  <Item key={i}>
                    <Spacer mb={{ xs: 20, sm: 20 }}>
                      <ImageContainer
                        noneClickable={props?.noneClickable || false}
                      >
                        {o.image && (
                          <div>
                            {props?.noneClickable ? (
                              <BackgroundImage image={o.image} />
                            ) : (
                              <BackgroundImage
                                image={o.image}
                                onClick={() => showLightBox(i)}
                              />
                            )}
                          </div>
                        )}
                      </ImageContainer>
                    </Spacer>

                    {o.headline && (
                      <Spacer mb={15}>
                        <Headline variant="h5" children={o.headline} />
                      </Spacer>
                    )}

                    {o.text && (
                      <Spacer pr={{ sm: 20 }}>
                        <Typography children={o.text} />
                      </Spacer>
                    )}
                  </Item>
                )
              })}
              <Item style={{ height: 0, overflow: "hidden", margin: 0 }} />
              <Item style={{ height: 0, overflow: "hidden", margin: 0 }} />
            </Grid>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div``

const Introduction = styled.div`
  max-width: 620px;
`

const Item = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  &:nth-last-child(3) {
    margin-bottom: 0;
  }

  @media (min-width: 640px) {
    width: calc(50% - 2px);
  }

  @media (min-width: 960px) {
    width: calc(100% / 3 - 2px);
  }
`

const ImageContainer = styled.div`
  height: 200px;
  width: 100%;
  background: #fff;
  cursor: ${({ noneClickable }) => (noneClickable ? "unset" : "pointer")};

  @media (min-width: 640px) {
    height: 230px;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const Headline = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.015em;
    text-align: left;
    text-transform: uppercase;
  }
`

export default Boxes
