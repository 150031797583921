import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { Typography } from "@material-ui/core"

// import app components
import { DesktopMenu, MobileMenu, Edges } from "components"
import Logo from "icons/walker-bay-logo-hz-black.svg"

import { useStore } from "store"
import * as theme from "theme"

export default props => {
  const { path } = props

  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <>
      <Container>
        <Edges size="lg">
          <Grid>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <Logo />
              </Link>
            </LogoContainer>

            <Navigation>
              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <StyledTypography children={`Menu`} />

                <HamburgerMenu
                  color={theme.colors.primary}
                  isOpen={menu}
                  width={28}
                  height={12}
                  strokeWidth={3}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Navigation>
          </Grid>
        </Edges>
      </Container>

      <MobileMenu />
    </>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 999;

  @media (min-width: 768px) {
    padding: 10px 0;
  }
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
`

const Grid = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-menu {
    @media (max-width: ${theme.breakpoints.down.md}) {
      display: none;
    }
  }
`

const LogoContainer = styled.div`
  a {
    display: flex;
    text-decoration: none;
  }

  svg {
    height: 25px;
  }
`

const HamburgerMenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  transform: translateX(15px);
  cursor: pointer;

  span {
    &:nth-child(2) {
      display: none !important;
    }
  }
`

const StyledTypography = styled(Typography)`
  && {
    display: none;

    @media (min-width: 768px) {
      display: block;
      font-family: "Noto Sans JP";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-right: 15px;
    }
  }
`
