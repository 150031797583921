import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"

// import app components
import { BackgroundImage } from "components"
import { useStore } from "store"
import PlayIcon from "icons/video-play.svg"

const VideoImage = props => {
  const {
    imageAlignment,
    image,
    responsive,
    youtubeUrl
  } = props

  const [, dispatch] = useStore()

  return (
    <ImageContainer
      alignment={imageAlignment}
      hasVideo={!!youtubeUrl}
      responsive={responsive}
      onClick={() =>
        !!youtubeUrl &&
        dispatch({
          type: "SET_LIGHTBOX",
          payload: {
            slide: 0,
            open: true,
            slides: [{ link: { url: youtubeUrl } }]
          }
        })
      }
    >
      <BackgroundImage image={image}>
        {youtubeUrl && (
          <PlayIconButton>
            <PlayIcon />
          </PlayIconButton>
        )}
      </BackgroundImage>
    </ImageContainer>
  )
}

const ImageContainer = styled(({ responsive, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  width: 100%;
  height: 238px;
  ${props => props.responsive && (
    `padding-bottom: 56.25%;`
  )}
  order: 1;
  margin-bottom: 30px;
  cursor: ${({ hasVideo }) => (hasVideo ? "pointer" : "default")};

  @media (min-width: 960px) {
    ${props => !props.responsive && (
      `width: 40%;
      height: unset;
      order: ${({ alignment }) => (alignment === "left" ? 1 : 3)};`
    )}
    margin-bottom: 0;

    > div {
      ${props => props.responsive ? (
        `max-width: 100%;`
      ) : (
        `max-width: 800px;`
      )}

      ${({ alignment }) =>
        alignment === "none"
          ? "" :
        alignment === "left"
          ? "left: unset !important; transform: translateX(-4px);"
          : "right: unset !important; transform: translateX(4px);"}
      ${({ hasVideo, responsive }) =>
        responsive
          ? "" :
        hasVideo
          ? "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 28px)"
          : "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 8px)"};
    }
  }
`

const PlayIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 220px !important;
      height: 144px !important;
    }
  }
`

export default VideoImage
