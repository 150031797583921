import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// import app components
import { BackgroundImage, Button, Edges, Textarea } from "components"

import { colors } from "theme"

const Reviews = props => {
  const { reviews } = props

  const { nodes: posts } = usePostQuery()
  const reviewCat = reviews?.[0]?.id || "all"

  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  }

  const CustomDot = ({ onClick, active, index, carouselState }) => {
    // const { currentSlide } = carouselState
    return (
      <CarouselLi onClick={() => onClick()}>
        <CarouselButton
          style={{ background: active ? colors.tertiary : "#535353" }}
        />
      </CarouselLi>
    )
  }

  const visibleReviews =
    reviewCat === `all`
      ? posts
      : posts.filter(
          o =>
            o?.reviewCategories.nodes &&
            o.reviewCategories.nodes.find(p => p.id === reviewCat)
        )

  return (
    <>
      <PostContainer>
        <Edges size="cont">
          <Carousel
            autoPlay={false}
            containerClass="carousel-container"
            customDot={<CustomDot />}
            dotListClass="carousel-container__dots"
            swipeable={false}
            draggable={false}
            showDots={true}
            keyBoardControl={true}
            responsive={carouselResponsive}
          >
            {visibleReviews &&
              visibleReviews.map((o, i) => {
                return (
                  <ReviewCont key={i}>
                    <ImageContainer>
                      <BackgroundImage image={o.acf.image} />
                    </ImageContainer>

                    {o.title && (
                      <StyledTypography variant="h3" children={o.title} />
                    )}

                    {o.acf.text && (
                      <TextCont>
                        <Textarea
                          className="excerpt-text"
                          content={o.acf.excerpt}
                        />
                      </TextCont>
                    )}

                    <Button
                      children={"See Review"}
                      to={o.uri}
                      variant="outlinedSecondary"
                    />
                  </ReviewCont>
                )
              })}
          </Carousel>
        </Edges>
      </PostContainer>
    </>
  )
}

const PostContainer = styled.div`
  background: #000;
  color: #fff;
  margin-top: 15px;
  min-width: 50vw;
  padding: 15px 0px 0px;

  .carousel-container {
    padding-bottom: 50px;
  }

  .carousel-container__dots {
    bottom: 20px;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
`

const ReviewCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  text-align: center;
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    height: 125px;
    width: 230px;
  }
`

const TextCont = styled.div`
  padding: 20px 0px;
  width: 100%;
`

const StyledTypography = styled(Typography)`
  width: 100%;
  padding: 0 50px;
`

const CarouselButton = styled.button`
  background: ${colors.tertiary};
  border: 0px;
  border-radius: 10px;
  height: 3px;
  margin: 0px 10px;
  outline: none;
  width: 41px;
`

const CarouselLi = styled.li`
  margin-top: 10px;
  padding: 10px 5px;
`

const usePostQuery = () => {
  const { allWpReview } = useStaticQuery(
    graphql`{
  allWpReview {
    nodes {
      acf {
        excerpt
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
      id
      title
      reviewCategories {
        nodes {
          id
        }
      }
      uri
    }
  }
}
`
  )
  return allWpReview
}

export default Reviews
