import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import "core-js/es6/number"
import "core-js/es6/array"

// import "mapbox-gl/dist/mapbox-gl.css"

// import app components
import Layout from "./Layout"
import { SEO } from "components"
import { mui, GlobalStyles } from "theme"
import { StoreProvider } from "store"

const App = props => {
  const { pageContext } = props

  return (
    <StoreProvider>
      <ThemeProvider theme={mui}>
        <Helmet>
          <html lang="en" />
          <meta name="description" />
        </Helmet>

        <SEO {...pageContext} />

        <CssBaseline />
        <GlobalStyles />

        <Layout {...props}>{props?.children}</Layout>
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App
