import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, BackgroundImage, BackgroundVideo } from "components"
import * as theme from "theme"

const Hero = props => {
  const { border, headline, heroHeight, image, video } = props

  const videoUrl = video ? video.localFile.publicURL : null

  return (
    <Container border={border} heroheight={heroHeight}>
      {headline && (
        <HeadlineContainer>
          <Edges size="md">
            <Typography variant="h1" children={headline} />
          </Edges>
        </HeadlineContainer>
      )}

      {videoUrl ? (
        <VideoContainer>
          <BackgroundVideo
            autoPlay={true}
            loop={true}
            muted={true}
            placeholder={image}
            controls={false}
            src={videoUrl}
          />
        </VideoContainer>
      ) : (
        <ImageContainer>
          <BackgroundImage image={image} />
        </ImageContainer>
      )}

    </Container>
  )
}

const Container = styled(({ heroheight, border, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  height: 340px;
  padding: ${({ border }) => (border ? "20px" : 0)};
  background: ${theme.colors.primary};

  @media (min-width: 768px) {
    height: ${props => (props.heroheight === "xs" ? "430px" : "70vh")};
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background: radial-gradient(
      39.7% 55.78% at 31.95% 112.59%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

const HeadlineContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    135.36deg,
    rgba(255, 255, 255, 0.4) 57.92%,
    rgba(166, 244, 255, 0.4) 67.77%,
    rgba(0, 139, 218, 0.4) 90.11%
  );
  backdrop-filter: blur(12px);

  h1 {
    margin-bottom: 12px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
`

const VideoContainer = styled.div`
  position: relative;
  height: 100%;
`

export default Hero
