import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"

// import app components
import { Edges, Spacer, Button, BackgroundImage, Textarea } from "components"
import * as theme from "theme"
import { useStore } from "store"
import PlayIcon from "icons/video-play.svg"

const TextImage = (props) => {
  const {
    text,
    imageAlignment,
    image,
    background,
    youtubeUrl,
    button,
    ...rest
  } = props

  const [, dispatch] = useStore()

  return (
    <Container background={background} {...rest}>
      <Spacer
        pt={{ xs: 20, md: background === "gradient" ? 80 : 20 }}
        pb={{ xs: 20, md: background === "gradient" ? 0 : 20 }}
      >
        <Edges size="ml">
          <Grid>
            <ImageContainer
              alignment={imageAlignment}
              hasVideo={!!youtubeUrl}
              onClick={() =>
                !!youtubeUrl &&
                dispatch({
                  type: "SET_LIGHTBOX",
                  payload: {
                    slide: 0,
                    open: true,
                    slides: [{ link: { url: youtubeUrl } }]
                  }
                })
              }
            >
              <BackgroundImage image={image}>
                {youtubeUrl && (
                  <PlayIconButton>
                    <PlayIcon />
                  </PlayIconButton>
                )}
              </BackgroundImage>
            </ImageContainer>

            <ContentContainer alignment={imageAlignment}>
              <div>
                {text && <Textarea content={text} />}

                {button && (
                  <Spacer mt={{ xs: 20, sm: 30 }}>
                    {button?.url && (
                      <Button
                        to={button.url}
                        children={button.title || "Read More"}
                        variant="outlined"
                        color="secondary"
                      />
                    )}
                  </Spacer>
                )}
              </div>
            </ContentContainer>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: ${({ background }) =>
    background === "gradient"
      ? "radial-gradient(77.11% 77.11% at 7.58% -22.46%, #252525 0%, #000000 100%)"
      : theme.colors.primary};
`

const Grid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  order: 1;
  margin-bottom: 30px;
  cursor: ${({ hasVideo }) => (hasVideo ? "pointer" : "default")};

  @media (min-width: 960px) {
    width: 40%;
    height: unset;
    order: ${({ alignment }) => (alignment === "left" ? 1 : 3)};
    margin-bottom: 0;

    > div {
      max-width: 800px;

      ${({ alignment }) =>
        alignment === "left"
          ? "left: unset !important; transform: translateX(-4px);"
          : "right: unset !important; transform: translateX(4px);"}

      ${({ hasVideo }) =>
        hasVideo
          ? "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 28px)"
          : "width: calc(1030px * 0.4 + (100vw - 1030px) / 2 - 8px)"};
    }
  }
`

const ContentContainer = styled.div`
  width: 100%;
  color: ${theme.colors.text.light};
  order: 2;
  padding-bottom: 30px;

  @media (min-width: 960px) {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 40px 0;

    ${(props) =>
      props.alignment === "left"
        ? "padding-left: 50px"
        : "padding-right: 50px"};
  }
`

const PlayIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 220px !important;
      height: 144px !important;
    }
  }
`

export default TextImage
