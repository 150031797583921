import { useState } from "react"

const initialState = {
  error: null,
  data: null,
  loading: false,
  called: false,
}

export const useLazyFetch = (endpoint, args: any = {}) => {
  const { token = null, method = "get", onCompleted, onError } = args
  const [state, setState] = useState(initialState)
  const headers = !!token ? { authorization: `Bearer ${token}` } : undefined

  const handleError = error => {
    setState({ ...state, error, loading: false, called: true })
    typeof onError === "function" && onError(error) //optional callback function
  }

  const callback = async callbackArgs => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null,
    })

    const combinedArgs = {
      ...args,
      ...callbackArgs,
    }

    await fetch(endpoint, {
      method,
      headers,
      ...combinedArgs,
    })
      .then(async response => {
        const json = await response.json()

        if (response.status === 200) {
          setState({
            ...state,
            loading: false,
            data: json,
            called: true,
            error: null,
          })
          typeof onCompleted === "function" && onCompleted(json) //optional callback function
        } else {
          handleError(json)
        }
      })
      .catch(error => {
        handleError(error)
      })
  }

  return [callback, state]
}

export default useLazyFetch
