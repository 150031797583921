const colors = {
  primary: "#000000",
  secondary: "#BCAC57",
  tertiary: "#51EAFF",
  background: {
    light: "#ffffff",
    dark: "#000000"
  },
  text: {
    light: "#DEDEDE",
    dark: "#252525"
  },
  success: "#0daf6c",
  error: "#e24141"
}

export default colors
