import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { Link } from "gatsby"
import { Edges, Spacer, Button } from "components"
import Parser from "html-react-parser"
import { graphql } from "gatsby"
import useLazyFetch from "../../hooks/useLazyFetch"
import { isBefore } from "date-fns"
import HorizentalLine from "../../images/lin-horizental.png"

// import app components

const UpcomingEvents = (props) => {
  const { eventsHeadline, eventsLink } = props.homeEvents

  const [getEvents, { data, error, loading, called }] = useLazyFetch(
    `${process.env.GATSBY_WP}/graphql`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: /* GraphQL */ `
          query EventsQuery {
            events(first: 300) {
              nodes {
                title
                acfEvent {
                  dealersName
                  endDate
                  eventLocation
                  fieldGroupName
                  startDate
                }
              }
            }
          }
        `
      })
    }
  )

  !called && getEvents()

  const activeEvents = data?.data?.events?.nodes?.filter((event) => {
    const eventDate = new Date(event.acfEvent.startDate)
    return isBefore(Date.now(), eventDate)
  })

  const orderedActiveEvents =
    activeEvents &&
    activeEvents.sort(
      (a, b) => new Date(a.acfEvent.startDate) - new Date(b.acfEvent.startDate)
    )

  return (
    <>
      <UpcomingEventsContainer>
        <Edges size="ml">
          <Box>
            {eventsHeadline && (
              <Spacer mb={{ xs: 10 }}>
                <Typography
                  variant="h2"
                  children={Parser(eventsHeadline)}
                  color="inherit"
                />
              </Spacer>
            )}
            {eventsLink.url && eventsLink.title && (
              <Spacer mt={{ xs: 10 }}>
                <Button
                  children={eventsLink.title}
                  color="secondary"
                  role="presentation"
                  variant="outlined"
                  to={eventsLink.url}
                />
              </Spacer>
            )}

            {activeEvents && activeEvents.length > 0 && (
              <ActiveEventContainer>
                {orderedActiveEvents &&
                  orderedActiveEvents?.slice(0, 3).map((event, index) => {
                    const EventStart = new Date(event?.acfEvent?.startDate)
                    const EventFinish = new Date(event?.acfEvent?.endDate)
                    return (
                      <ActiveEventSingle key={index} index={index}>
                        {index === 0 && (
                          <SvgLine>
                            <img src={HorizentalLine} />
                          </SvgLine>
                        )}
                        <DateBox>
                          <Typography
                            variant="body1"
                            children={`${EventStart.toLocaleString("default", {
                              month: "short"
                            }).toUpperCase()} | ${EventStart.getFullYear()}`}
                          />
                          <Typography
                            className="dayDate"
                            children={`${("0" + EventStart.getDate()).slice(
                              -2
                            )}-${("0" + EventFinish.getDate()).slice(-2)} `}
                          />
                        </DateBox>
                        <TitleBox>
                          {event?.title && (
                            <Typography
                              variant="body1"
                              children={event?.title}
                            />
                          )}
                          {event?.acfEvent?.eventLocation && (
                            <Typography
                              variant="caption"
                              children={event?.acfEvent?.eventLocation}
                            />
                          )}
                        </TitleBox>
                      </ActiveEventSingle>
                    )
                  })}
              </ActiveEventContainer>
            )}
          </Box>
        </Edges>
      </UpcomingEventsContainer>
    </>
  )
}

const UpcomingEventsContainer = styled.div`
  padding: 60px 0;
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  color: white;
`
const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`
const ActiveEventContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  position: relative;
  @media (min-width: 880px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
const ActiveEventSingle = styled.div`
  border: ${(props) => (props.index === 0 ? "none" : "1px solid #ffffff")};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 220px;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-bottom: 20px;
  @media (min-width: 880px) {
    width: 32.5%;
  }
`
const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  gap: 2%;
  .dayDate {
    font-style: normal;
    font-weight: 100;
    font-size: 50px;
    line-height: 50px;
    /* identical to box height, or 100% */

    text-align: center;
    letter-spacing: -0.015em;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  gap: 2%;
`
const SvgLine = styled.div`
  align-items: center;
  display: block;
  height: 100%;
  justify-content: center;
  margin-top: -15px;
  position: absolute;
  top: 0;
  left: 0;
`

export default UpcomingEvents
