import React from "react"
import styled from "styled-components"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// import app components
import { Edges, Spacer, BackgroundImage, Textarea } from "components"
import * as theme from "theme"
import { useStore } from "store"

const HomeTextImage = (props) => {
  const {
    content,
    images, // assuming images is an array of image objects
    ...rest
  } = props

  const [, dispatch] = useStore()

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  console.log("Images prop:", images) // Debugging line to check the images prop

  return (
    <Container {...rest}>
      <Spacer>
        <Edges size="lg">
          <Grid>
            <ImageContainer>
              <Carousel
                responsive={responsive}
                infinite={true}
                showDots={true}
                arrows={false}
              >
                {images.map(({ image }, index) => (
                  <CarouselItem key={index}>
                    <BackgroundImageContainer>
                      <BackgroundImage image={image} />
                    </BackgroundImageContainer>
                  </CarouselItem>
                ))}
              </Carousel>
            </ImageContainer>
            <ContentContainer>
              <div>{content && <Textarea content={content} />}</div>
            </ContentContainer>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: ${({ background }) =>
    background === "gradient"
      ? "radial-gradient(77.11% 77.11% at 7.58% -22.46%, #252525 0%, #000000 100%)"
      : theme.colors.primary};
`

const Grid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (min-width: 960px) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  order: 1;
  margin-bottom: 30px;
  height: 300px;

  @media (min-width: 960px) {
    width: 50%; /* Make the carousel wider than the container */
    order: ${({ alignment }) => (alignment !== "left" ? 1 : 3)};
    margin-bottom: 0;
    margin-left: -10%; /* Adjust the left margin to overflow */
  }
`

const ContentContainer = styled.div`
  width: 100%;
  color: ${theme.colors.text.light};
  order: 2;
  padding-bottom: 30px;

  @media (min-width: 960px) {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 40px 0;

    ${(props) =>
      props.alignment !== "left"
        ? "padding-left: 50px"
        : "padding-right: 50px"};
  }
`

const CarouselItem = styled.div`
  width: 100%;
  height: 300px; /* Ensure the height is defined */

  @media (min-width: 960px) {
    width: 100%;
    height: 600px; /* Ensure the height is defined */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export default HomeTextImage
