import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, FooterMenu, Socials } from "components"

import * as theme from "theme"
import Logo from "icons/walker-bay-logo-hz-black.svg"
import BuildInUSA from "images/built-in-usa.jpg"

export default () => {
  var d = new Date()

  return (
    <>
      <Footer>
        <Edges size="ml">
          <Grid>
            <FooterMenu />

            <Column>
              <LogoContainer>
                <Logo />
              </LogoContainer>

              <Socials />

              <BuiltInUSA>
                <img src={BuildInUSA} alt="Built in USA" />
                <Typography
                  color="inherit"
                  children="All Walker Bay products are proudly designed and built in North America"
                />
              </BuiltInUSA>
            </Column>
          </Grid>
        </Edges>
      </Footer>

      <Legal>
        <Edges size="ml">
          <StyledTypography>
            ©{d.getFullYear()} WALKER BAY BOATS.{" "}
            <a href="https://jambaree.com" target="_blank" rel="noopener">
              WEBSITE BY JAMBAREE
            </a>
          </StyledTypography>
        </Edges>
      </Legal>
    </>
  )
}

const Footer = styled.div`
  padding: 50px 0;
  background: ${theme.colors.primary};
`

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Column = styled.div``

const LogoContainer = styled.div`
  margin-bottom: 40px;

  svg {
    height: 25px;

    path {
      fill: #fff;
    }
  }
`

const Legal = styled.div`
  padding: 10px 0;
  background: ${theme.colors.primary};
`

const StyledTypography = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #747474;

    a {
      margin-left: 4px;
      color: ${theme.colors.secondary};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const BuiltInUSA = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  max-width: 380px;
  color: #fff;

  img {
    margin-right: 20px;
    width: 80px;
  }
`
